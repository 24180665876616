<template src="../templates/order-info.html"></template>
<script>

import Button from '@f/Button';
import OrderInfoModal from './OrderInfoModal';

export default {
    name: 'OrderInfo',
    components: {
        OrderInfoModal,
        Button,
    },
    props: {
        orderInfo: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    emits: ['update:orderInfo'],
    data() {
        return {
            showModal: false,
        };
    },
    computed: {
        partnerChosen() {
            return !!this.orderInfo.partner_code;
        },
        partnerNotChosenWarningBtn() {
            return this.partnerChosen ? 'Изменить' : 'Выбрать';
        }
    },
    methods: {
        showModalHandler() {
            this.showModal = true;
        },
        changeOrderInfo(info) {
            this.$emit('update:orderInfo', {...this.orderInfo, ...info});
        }
    },
};
</script>

<style lang="scss" scoped>
.order-info {
    gap: 8px;

    &__partner-not-chosen-warning {
        color: red;
        font-weight: 700;
    }
}
</style>
