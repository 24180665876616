<template src="../../templates/selection/compare-products.html"></template>
<script>



import FormRadio from '@f/Radio';
import FormButton from '@f/Button';
import Modal from '@c/Modal';
import Grid from '@c/Grid';

import ColumnImage from './Column/Image';
import ColumnTitle from './Column/Title';
import ColumnPrice from './Column/Price';
import ColumnStockAvailability from './Column/StockAvailability';
import ColumnDelimiter from './Column/Delimiter';
import ColumnPoints from './Column/Points';
import ColumnProperty from './Column/Property';
import ColumnDefault from './Column/Default';
import ColumnAnswerPoints from './Column/AnswerPoints';
import ColumnFeature from './Column/Feature';
import ColumnList from './Column/List';

import '../../styles/compare-products.scss';

export default {
    name: 'CompareProducts',
    components: {
        FormButton,
        FormRadio,
        Modal,
        Grid,

        ColumnImage,
        ColumnTitle,
        ColumnPrice,
        ColumnStockAvailability,
        ColumnDelimiter,
        ColumnPoints,
        ColumnProperty,
        ColumnDefault,
        ColumnAnswerPoints,
        ColumnFeature,
        ColumnList,
    },
    props: {
        modes: {
            type: Array,
            default: () => ['selection', 'features'],
        },
        products: {
            type: Object,
            default: () => ({}),
        },
        questions: {
            type: Array,
            default: () => ([]),
        },
        titles: {
            type: Object,
            default: () => ({}),
        },
        details: {
            type: Object,
            default: () => ({}),
        },
        displayCompareProductsPopup: {
            type: [Boolean, Number],
            default() {
                return false;
            }
        },
    },
    emits: ['update:displayCompareProductsPopup'],
    data() {
        return {
            selectedFinancialProduct: null,
            potentialFinancialProduct: null,
        };
    },
    computed: {
        topProducts() {
            return this.products.slice(0, 3);
        },
        requiredQuestions() {
            const result = [];

            if (this.questions && this.questions.length > 0) {
                this.questions.forEach(question => {
                    if (
                        question.required
                        && (
                            typeof question.answer === 'undefined'
                            || question.answer.length === 0
                        )
                    ) {
                        result.push(question);
                    }
                });
            }

            return result;
        },
        answerTooltips() {
            const result = {};
            if (this.questions && this.questions.length > 0) {
                this.questions.forEach(question => {
                    if (question.answers && question.answers.length > 0) {
                        question.answers.forEach(answer => {
                            result[answer.id] = answer.comment;
                        });
                    }
                });
            }
            return result;
        },
        feed() {

            const columns = [
                {
                    name: 'preview_url',
                    hidden: true,
                    component: 'ColumnImage',
                    toFooter: true,
                    additional: ['link'],
                },
                {
                    name: 'name',
                    hidden: true,
                    component: 'ColumnTitle',
                    toFooter: true,
                    additional: ['link'],
                },
                {
                    name: 'selected',
                    hidden: true,
                    component: 'ColumnPrice',
                    toFooter: true,
                },
                {
                    name: 'stockAvailability',
                    hidden: true,
                    component: 'ColumnStockAvailability',
                    toFooter: true,
                },
            ];
            if (this.modes.includes('selection')) {
                columns.push({
                    name: 'points',
                    title: 'Сценарий клиента',
                    component: 'ColumnPoints',
                    toFooter: false,
                    class: 'delimiter'
                });
            }


            let result = [];
            if (this.products) {

                const footerLines = [];

                const buildColumn = (name, data = {}, options = {}) => ({
                    name: name,
                    data: data,
                    component: (options.component) ? options?.component : null,
                    options,
                });

                const buildDelimiter = (title, productLength = 3, options = {}) => {
                    const line = [];
                    line.push(buildColumn(
                        title,
                        {
                            type: 'delimiter',
                        },
                        {
                            ...{
                                component: 'ColumnDelimiter',
                                colspan: productLength + 1,
                            },
                            ...options
                        }
                    ));
                    return line;
                };

                const filterData = (data, fields = []) => {
                    const filtredData = {};

                    fields.forEach(key => {
                        if (typeof data[key] !== 'undefined') {
                            filtredData[key] = data[key];
                        } else {
                            filtredData[key] = null;
                        }
                    });

                    return filtredData;
                };

                // Cписок товаров с превью
                columns.forEach(column => {
                    const line = [];
                    if (typeof column.hidden !== 'undefined' && column.hidden) {
                        line.push(buildColumn(' '));
                    } else {
                        line.push(buildColumn(
                            column.title ? column.title : column.name,
                            {},
                            {class: `title${column.class ? ` ${column.class}` : ''}`}
                        ));
                    }
                    // line.push(line[line.length - 1]);

                    this.topProducts.forEach(product => {
                        let additional = {};
                        if (column.additional) {
                            additional = filterData(product, column.additional);
                        }

                        const type = typeof product[column.name];

                        let productData = {};
                        if (type !== 'string') {
                            productData = product[column.name];
                        }

                        line.push(buildColumn(
                            product[column.name],
                            {
                                ...productData,
                                ...additional,
                            },
                            column
                        ));
                    });
                    result.push(line);
                    if (column.toFooter) {
                        footerLines.push(line);
                    }
                });

                // Список вопросов и ответов.
                if (this.modes.includes('selection')) {
                    if (this.requiredQuestions.length > 0) {
                        this.requiredQuestions.forEach(requiredQuestion => {
                            result.push(buildDelimiter(
                                `Не ответили на вопрос: "${requiredQuestion.description}"`,
                                this.topProducts.length,
                                {
                                    class: 'text-red mw-1 font-weight-normal'
                                }
                            ));
                        });
                    }

                    Object.keys(this.titles).forEach(key => {
                        const answerKeys = Object.keys(this.titles[key].answers);
                        if (answerKeys.length > 0) {
                            answerKeys.forEach(answerKey => {

                                const answer = this.titles[key].answers[answerKey];
                                const line = [];

                                line.push(buildColumn(
                                    answer.title,
                                    {},
                                    {
                                        class: 'title pt-2 pr-2',
                                        /* // Пример тултипа, который поддерживается полем по умолчанию
                                    tooltip: {
                                        id: `compare-answer-comment-${answer.id}`,
                                        comment: this.getAnswerTooltip(answer.id)
                                    }*/
                                    }
                                ));

                                /* убираем дублирование левого столбца
                            line.push(buildColumn(
                                answer.title,
                                {
                                    class: 'title mt-2 mr-2'
                                }
                            ));/**/

                                this.topProducts.forEach(product => {
                                    if (
                                        typeof this.details[product.id].questions[key] !== 'undefined'
                                        && this.details[product.id].questions[key].answers[answerKey]
                                    ) {
                                        line.push(
                                            buildColumn(
                                                this.details[product.id].questions[key].answers[answerKey].points,
                                                {},
                                                {component: 'ColumnAnswerPoints'}
                                            )
                                        );
                                    } else {
                                        line.push(buildColumn('-'));
                                    }
                                });
                                result.push(line);

                                const comment = this.getAnswerTooltip(answer.id);
                                if (comment && comment !== '') {
                                    result.push(buildDelimiter(
                                        comment,
                                        this.topProducts.length,
                                        {
                                            class: 'tooltip'
                                        }
                                    ));
                                }


                                // Подбор по ТТХ внутри ответа...
                                const propertiesKeys = Object.keys(answer.properties);
                                if (propertiesKeys.length > 0) {
                                    propertiesKeys.forEach(propertyKey => {
                                        const subLine = [];
                                        const propertyDetails = answer.properties[propertyKey];

                                        subLine.push(buildColumn(
                                            ` - ${propertyDetails.title}`,
                                            {},
                                            {class: 'ml-2'}
                                        ));
                                        // subLine.push(subLine[subLine.length - 1]);

                                        this.topProducts.forEach(product => {
                                            if (
                                                typeof this.details[product.id].questions[key] !== 'undefined'
                                                && this.details[product.id].questions[key].answers[answerKey]
                                                && this.details[product.id].questions[key].answers[answerKey].properties[propertyKey]
                                            ) {
                                                const dataItem = this.details[product.id].questions[key].answers[answerKey].properties[propertyKey];

                                                // component
                                                subLine.push(
                                                    buildColumn(
                                                        '',
                                                        {
                                                            title: `${dataItem.value}`,
                                                            points: dataItem.points,
                                                        },
                                                        {component: 'ColumnProperty'}
                                                    )
                                                );
                                            } else {
                                                subLine.push(buildColumn('-'));
                                            }
                                        });
                                        result.push(subLine);
                                    });
                                }
                            });
                        }
                    });
                }

                // Комплектации
                const hasOptions = this.topProducts.filter(product => product.options.length);
                if (hasOptions) {
                    result.push(buildDelimiter('Комплектации', this.topProducts.length));

                    const optionsLine = [];
                    optionsLine.push(buildColumn(''));
                    this.topProducts.forEach(product => {
                        let optionsNames = [];
                        if (product.options.length) {
                            optionsNames = product.options.map(option => option.metadata.title);
                        }

                        optionsLine.push(buildColumn(
                            '',
                            {
                                list: optionsNames,
                            },
                            {
                                component: 'ColumnList',
                            }
                        ));
                    });
                    result.push(optionsLine);
                }


                // ТТХ
                if (this.modes.includes('features')) {
                    result.push(buildDelimiter('Характеристики', this.topProducts.length));
                    const groupFeatures = features => {
                        const featuresGrouped = {};
                        if (features.values && features.values.length > 0) {
                            // eslint-disable-next-line no-return-assign
                            features.values.forEach(value => featuresGrouped[value.feature.id] = value);
                        }
                        if (features.ranges && features.ranges.length > 0) {
                            // eslint-disable-next-line no-return-assign
                            features.ranges.forEach(range => featuresGrouped[range.feature.id] = range);
                        }
                        return featuresGrouped;
                    };
                    if (this.features.length > 0) {
                        this.features.forEach(feature => {

                            const line = [];

                            line.push(buildColumn(
                                feature.name,
                                {
                                    class: 'mt-2',
                                }
                            ));

                            this.topProducts.forEach(product => {
                                const productFeatures = groupFeatures(product.features);
                                if (
                                    typeof productFeatures[feature.id] !== 'undefined'
                                ) {
                                    line.push(
                                        buildColumn(
                                            feature.id,
                                            {...{type: feature.type}, ...productFeatures[feature.id]},
                                            {component: 'ColumnFeature'}
                                        )
                                    );
                                } else {
                                    line.push(buildColumn('-'));
                                }
                            });

                            result.push(line);
                        });
                    }

                    // Напоминаем какие товары смотрим
                    if (footerLines.length > 0) {
                        result.push(buildDelimiter(' ', this.topProducts.length));
                        result = [...result, ...footerLines];
                    }
                }

            }

            // console.log(result, 'RESULT');
            // console.log(this.topProducts, 'this.topProducts');
            // console.log(this.titles, 'this.titles');
            // console.log(this.details, 'this.details');
            // console.log('------------------');

            return result;
        },
        features() {
            const features = {};//ranges values // feature
            if (this.topProducts && this.topProducts.length > 0) {
                this.topProducts.forEach(product => {
                    [...product.features.values, ...product.features.ranges].forEach(featureValue => {
                        if (
                            typeof features[featureValue.feature.id] === 'undefined'
                            && featureValue.feature.disabled === 0
                        ) {
                            features[featureValue.feature.id] = featureValue.feature;
                        }
                    });
                });
            }

            return Object.values(features);
        },
    },
    watch: {
        products: {
            handler() {
                if (!this.products.length) {
                    this.updateDisplayCompareProductsPopup(false);
                }
            }
        }
    },
    methods: {
        updateDisplayCompareProductsPopup(display) {
            this.$emit('update:displayCompareProductsPopup', display);
        },
        getAnswerTooltip(answerId) {
            return typeof this.answerTooltips[answerId] !== 'undefined' && this.answerTooltips[answerId]
                ? this.answerTooltips[answerId]
                : null;
        }
    }
};
</script>
