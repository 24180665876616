<template src="./column-list.html"></template>

<script>
export default {
    name: 'ColumnList', 
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
