<template src="../../templates/selection/selection.html"></template>
<script>

import {updateCalculateResult, updateCalculatePartsResult} from '@/api/questionnaire';
import currencyFilter from '@/filters/currency';
import Price from '@/entities/price';
import helpers from '@/tools/helpers';
import Modal from '@c/Modal/index';
import {Tippy} from 'vue-tippy';
import FormRadio from '@f/Radio';
import FormCheckbox from '@f/Check';
import FormButton from '@f/Button';
import Loader from '@c/Loader';
import FinanceQuestion from './FinanceQuestion';
import CompareProducts from './CompareProducts';
import ProductAvailability from './ProductAvailability';
import ScenarioAssessment from '../ScenarioAssessment';
import PartPrice from './PartPrice';

export default {
    name: 'Selection',
    components: {
        PartPrice,
        FormRadio,
        FormButton,
        FormCheckbox,
        Tippy,
        Modal,
        Loader,
        FinanceQuestion,
        ScenarioAssessment,
        CompareProducts,
        ProductAvailability,
    },
    props: {
        mode: {
            type: [Number, String],
            default() {
                return '';
            }
        },
        questionnaireOptions: {
            type: Object,
            default() {
                return null;
            }
        },
        questionnaire: {
            type: Object,
            default() {
                return null;
            }
        },
        scenarioRatings: {
            type: Object,
            default() {
                return null;
            }
        },
        questionnaireId: {
            type: [Number, String],
            default() {
                return null;
            }
        },
        questions: {
            type: [Array, Object],
            default() {
                return [];
            }
        },
        questionsSource: {
            type: [Array, Object],
            default() {
                return [];
            }
        },
        recalculate: {
            type: Boolean,
            default() {
                return false;
            }
        },
        addSelectedProduct: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        removeSelectedProduct: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        setCalculateResult: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        selectedProducts: {
            type: Array,
            default() {
                return [];
            }
        },
        calculateResult: {
            type: Object,
            default() {
                return {
                    products: []
                };
            }
        },
        isProductPricesDisplayed: {
            type: Boolean,
            default() {
                return false;
            },
        },
        calculateTitles: {
            type: Object,
            default() {
                return null;
            }
        },
        detailsCalculation: {
            type: Object,
            default() {
                return null;
            }
        },
    },
    emits: ['update:isProductPricesDisplayed'],
    data() {
        return {
            inStockCheck: false,
            displayProductPrices: this.isProductPricesDisplayed,
            displayEquipmentField: 0,
            displayedAllProductPrices: [],
            displayFinancialPopup: false,
            productForFinPopup: null,
            selectedFinancialProduct: null,
            displayCompareProducts: false,
            displayCalculationDetails: false,
            viewAvailability: null,
            calculationLoaded: false,
        };
    },
    computed: {
        priceTool() {
            return new Price();
        },
        branches() {
            return this.$store.state.Catalogs.branches;
        },
        variables() {
            const questionsArray = Object.values(this.questions);
            const result = {};

            for (let i = 0; i < questionsArray.length; i++) {
                if (questionsArray[i].value_name) {
                    result[questionsArray[i].value_name] = [];

                    questionsArray[i].answers.forEach(
                        item => {
                            if (item.value) {
                                result[questionsArray[i].value_name].push(item.value);
                            }
                        }
                    );
                }
            }

            return result;
        },
        selectedVariables() {
            const questionsArray = Object.values(this.questions);
            const result = {};
            for (let i = 0; i < questionsArray.length; i++) {
                if (questionsArray[i].answers?.length && questionsArray[i]?.answer?.length) {
                    if (questionsArray[i].value_name) {
                        result[questionsArray[i].value_name] = [];

                        questionsArray[i].answers.forEach(
                            item => {
                                if (questionsArray[i].answer.includes(item.id) && item.value) {
                                    result[questionsArray[i].value_name].push(item.value);
                                }
                            }
                        );
                    }
                }
            }
            return result;
        },
        // функция калькуляции, которая не будет вызываться чаще 1000мс
        debouncedCalculate() {
            return helpers.debounceBuffer(this.calculateQuestionnaire, 1000);
        },
        getPricesFromProductForFinPopup() {
            const result = [
                'price',
                'sber'
            ];

            if (this.productForFinPopup !== null) {
                const {prices} = JSON.parse(JSON.stringify(this.productForFinPopup));

                if (prices.installment !== undefined) {
                    result.push('installment');
                }
                if (prices.leasing !== undefined) {
                    result.push('leasing');
                }
                if (prices.credit !== undefined) {
                    result.push('credit');
                }
            }

            return result;
        },

        productsForCompare() {
            const result = [];
            if (
                this.calculateResult.products
                && this.selectedProducts
                && this.selectedProducts.length > 0
            ) {
                let selectedIds = [];
                const selectedGrouped = {};

                this.selectedProducts.forEach(selected => {
                    selectedIds.push(selected.id);
                    selectedGrouped[selected.id] = selected;
                });

                selectedIds = this.selectedProducts.map(x => x.id);
                this.calculateResult.products.forEach(product => {
                    if (selectedIds.includes(parseInt(product.id, 10))) {
                        result.push({
                            ...product,
                            ...{selected: selectedGrouped[product.id]},
                            ...{
                                stockAvailability: {
                                    fastestDelivery: product.fastestDelivery,
                                    count: product.count,
                                    full_count: product.full_count,
                                }
                            },
                        });
                    }
                });
            }
            return result;
        },
        detailsForCompare() {
            let selectedIds = [];
            const result = {};
            if (this.selectedProducts && this.selectedProducts.length > 0) {
                selectedIds = this.selectedProducts.map(x => x.id);
                Object.keys(this.detailsCalculation).forEach(productId => {
                    if (selectedIds.includes(parseInt(productId, 10))) {
                        result[productId] = this.detailsCalculation[productId];
                    }
                });
            }
            return result;
        },
        isCompareAvailable() {
            return Object.values(this.selectedProducts).length > 1;
        },
        compareModes() {
            const result = ['features'];
            if (this.questionnaire.product_compare) {
                result.push('selection');
            }
            return result;
        }
    },
    watch: {
        recalculate: {
            handler(val) {
                if (val === true) {
                    this.debouncedCalculate();
                }
            },
        },
        selectedVariables() {
            if (
                typeof this.selectedVariables.finance !== 'undefined'
                && this.selectedVariables.finance.length
            ) {
                this.displayProductPrices = true;
                // eslint-disable-next-line prefer-destructuring
                this.selectedFinancialProduct = this.selectedVariables.finance[0];
            } else {
                this.displayProductPrices = false;
                this.selectedFinancialProduct = null;
            }
        },
        displayProductPrices() {
            this.$emit('update:isProductPricesDisplayed', this.displayProductPrices);
        },
    },
    methods: {
        inStockChange(event) {
            this.inStockCheck = event.target.checked;
        },
        onDisplayCalculationDetails(event) {
            this.displayCalculationDetails = event.target.checked;
        },
        formattedPrice(price, currency = 'RUB') {
            return currencyFilter(price, currency);
        },
        openPrice(external_id) {
            this.displayedAllProductPrices.push(external_id);
        },
        generateNote(details) {
            let content = '';

            if (typeof details.price !== 'undefined') {
                content += `${details.price.term}: ${this.formattedPrice(details.price.total)}<br />`;
            }
            if (typeof details.priceWithoutCommissioning !== 'undefined') {
                content += `${details.priceWithoutCommissioning.term}: ${this.formattedPrice(details.priceWithoutCommissioning.total)}<br /><br />`;
            }
            if (typeof details.commissioning !== 'undefined') {
                content += `${details.commissioning.term}: ${this.formattedPrice(details.commissioning.total)}<br />`;
                if (typeof details.commissioning?.termEngineer !== 'undefined' && details.commissioning.engineerVisitPrice !== 0) {
                    content += `<span>    в т.ч.</span> ${details.commissioning.termEngineer} `;
                    content += `<span>(${this.questionnaireOptions.partnerDistance} км)</span>: ${this.formattedPrice(details.commissioning.engineerVisitPrice)}<br /><br />`;
                }
                if (typeof details.commissioning?.description !== 'undefined') {
                    content += `${details.commissioning.description}<br /><br />`;
                }
            }


            if (typeof details.term !== 'undefined') {
                content += `Срок ${details.term} месяцев<br />`;
            }

            if (typeof details.prepaid !== 'undefined' && typeof details.prepaid.term !== 'undefined') {
                content += `${details.prepaid.term}: ${details.prepaid.percent}% (${this.formattedPrice(details.prepaid.payment)})<br /><br />`;
            }

            if (typeof details.payments !== 'undefined') {
                content += 'Платежи:<br />';
                for (let i = 0; i < details.payments.length; i++) {
                    content += `${details.payments[i].term}: ${this.formattedPrice(details.payments[i].payment)}<br />`;
                }
            }

            return content;
        },
        generateCountBlock(details, fastestDelivery) {
            let html = '';
            const actualCounts = [];
            if (this.branches && details) {
                this.branches.forEach(branch => {
                    if (
                        typeof details[branch.id] !== 'undefined'
                        && details[branch.id] > 0
                    ) {
                        actualCounts.push({
                            id: branch.id,
                            name: branch.full_name,
                            count: details[branch.id]
                        });
                    }
                });
            }

            // Проверка наличия на филиалах
            const inStock = actualCounts.filter(obj => obj.id === this.questionnaireOptions.filial_id);
            const inStockSmk = actualCounts.filter(obj => obj.id === 1);

            if (inStock.length === 1) {
                html += '<div><span style="color: #39e867">Доступно на вашем филиале</span></div><br />';
            }

            // Отображение ближайшей даты поставки
            if (fastestDelivery) {
                html += `<div>Ближайшая поставка: ${fastestDelivery}</div><br />`;
            }

            if (actualCounts.length) {
                html += '<div>Свободно к продаже:</div>';

                // Сортировка по количеству
                actualCounts.sort(
                    // eslint-disable-next-line no-nested-ternary
                    (a, b) => ((a.count === b.count) ? 0 : (a.count > b.count) ? -1 : 1)
                );

                // Поднятие Смоленска на первую позицию
                if (inStockSmk.length === 1) {
                    const filialNameSmk = inStockSmk[0].name;
                    // eslint-disable-next-line no-nested-ternary
                    actualCounts.sort((x, y) => (x.name === filialNameSmk ? -1 : y.name === filialNameSmk ? 1 : 0));
                }

                // Поднятие текущего филиала на первую позицию
                if (inStock.length === 1) {
                    const filialName = inStock[0].name;
                    // eslint-disable-next-line no-nested-ternary
                    actualCounts.sort((x, y) => (x.name === filialName ? -1 : y.name === filialName ? 1 : 0));
                }

                // Отображение филиалов и количества
                actualCounts.forEach(count => {
                    // eslint-disable-next-line no-nested-ternary
                    const color = this.questionnaireOptions.filial_id === count.id ? '#39e867'
                        : count.id === 1 ? '#fde759' : '#fff';

                    html += `\t• <span style="color: ${color}">${count.name} — ${count.count} шт.</span><br/>`;
                });
            }

            return html;
        },
        getDisplayedPrices(prices) {
            const finishPrices = [];

            const values = Object.values(prices);
            for (let i = 0; i < values.length; i++) {
                if (
                    typeof this.priceTool.priceTypeDetails[values[i].type] !== 'undefined'
                    && this.priceTool.priceTypeDetails[values[i].type].display
                    && values[i].local_price
                ) {
                    finishPrices.push(values[i]);
                }
            }

            return finishPrices;
        },
        isCheckedPrice(productId, priceType) {
            if (this.selectedProducts.length === 0) {
                return false;
            }
            return this.selectedProducts.some(
                ({external_id, payment_type}) => (external_id === productId && payment_type === priceType)
            );
        },

        productChangeHandler(e, item, priceType) {
            if (e.target.checked) {
                this.addSelectedProduct(item, priceType);
            } else {
                this.removeSelectedProduct(item.external_id, priceType);
            }
        },

        async calculateQuestionnaire() {
            let result = [];
            this.calculationLoaded = false;

            if (this.questionnaire.isPart) {
                result = await updateCalculatePartsResult({
                    options: this.questionnaireOptions,
                    questionnaire_id: this.questionnaireId,
                    question: this.questions
                });
            } else {
                const {isPersonal = 0} = this.$route.query;

                // выбранные продукты отправляем в калькуляцию только для персонализации, чтобы не показывать другие
                const selectedProducts = (isPersonal && this.selectedProducts.length) ? this.selectedProducts : null;
                result = await updateCalculateResult({
                    options: this.questionnaireOptions,
                    questionnaire_id: this.questionnaireId,
                    selectedProducts,
                    question: this.questions
                });
            }

            this.updateCalculateResult(result);
        },

        updateCalculateResult(result) {

            const items = [...result.products];

            for (let i = 0; i < items.length; i++) {
                const all = items[i].points.all_points + Math.abs(items[i].points.negative_points);
                const bad = Math.floor((Math.abs(items[i].points.negative_points) / all) * 100);
                const good = Math.floor((items[i].points.all_points / all) * 100);
                items[i].graph = {
                    bad: bad,
                    neutral: 0,
                    good: good
                };
            }

            this.setCalculateResult({...result, products: items});
            this.calculationLoaded = true;
        },
        selectFinancialProduct(finProductCode) {
            if (finProductCode) {
                //выбрать товар, под которым кликнули для вызова выбора фин-продукта
                this.addSelectedProduct(
                    this.productForFinPopup,
                    finProductCode
                );

                this.selectedFinancialProduct = finProductCode;
                this.displayProductPrices = true;
            }
        },
        displayFinPopup(product) {
            this.displayFinancialPopup = true;
            this.productForFinPopup = product;
        },
        toggleAvailabilityBlock(productId = null) {
            if (this.viewAvailability !== productId) {
                this.viewAvailability = productId;
            } else {
                this.viewAvailability = null;
            }
        },
    },
};
</script>
