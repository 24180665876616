const categoryTypeTechnics = 5507;

// что сохраняем в 1С технику или запчасти
const c1SaveDirectionTechnics = 1;
const c1SaveDirectionSpareParts = 2;

// режимы работы Опросника
const questionnaireModeNew = 'new';
const questionnaireModeEdit = 'edit';

const PRICE_TYPE_PREPAYMENT = 'prepayment';
const PRICE_TYPE_PRICE = 'price';

const REQUIRED_PRODUCTS_COUNT_FOR_CATEGORY = 3;

const LOADING_MSG_CREATING_ORDER = 'Сохранение заказа...';
const LOADING_MSG_CREATING_OFFER = 'Создание оферты...';

const ORDER_CREATE_DEFAULT_TIMEOUT = 50000;

export {
    categoryTypeTechnics,
    c1SaveDirectionTechnics,
    c1SaveDirectionSpareParts,
    questionnaireModeNew,
    questionnaireModeEdit,
    PRICE_TYPE_PREPAYMENT,
    PRICE_TYPE_PRICE,
    REQUIRED_PRODUCTS_COUNT_FOR_CATEGORY,
    LOADING_MSG_CREATING_ORDER,
    LOADING_MSG_CREATING_OFFER,
    ORDER_CREATE_DEFAULT_TIMEOUT
};
