<template src="./templates/index.html"></template>
<script>
import './styles/questionnaire-page.scss';

import {save1COrder as save1COrderApi} from '@/api/1c';
import {
    c1SaveDirectionSpareParts,
    c1SaveDirectionTechnics,
    categoryTypeTechnics,
    questionnaireModeEdit,
    questionnaireModeNew,
    ORDER_CREATE_DEFAULT_TIMEOUT
} from '@/constants';
import Filter from '@/entities/filter';
import filterHelper from '@/entities/filter_helper';
import {getContactApi, getPartnerApi, getAreaCoords} from '@api/partners';
import PageTitle from '@c/PageTitle';
import helpers from '@/tools/helpers';
import Loader from '@c/Loader';
import {
    getQuestionnaireDetails,
    getQuestions,
    getModelLinesForQuestionnaire,
    setQuestionnaireResult,
    getQuestionnaireResult
} from '@/api/questionnaire';
import {createOffer, getOfferData} from '@/api/offer';
import {createKp} from '@/api/kp';
import {getUserInfo} from '@/api/user';

import eventBus from '@/entities/eventBus';
import CartForm from '@c/CartForm';
import OfferCartForm from '@c/OfferCartForm';
import Modal from '@c/Modal';
import PartnerList from '@page/PartnerList';
import OrderInfo from './components/OrderInfo';
import News from './components/News';
import KpLinks from './components/KpLinks';
import SelectionResult from './components/SelectionResult';
import Questions from './components/Questions';
import SideBar from './components/SideBar';

export default {
    name: 'Questionnaire2',
    components: {
        PageTitle,
        OrderInfo,
        News,
        KpLinks,
        SelectionResult,
        Questions,
        Loader,
        SideBar,
        CartForm,
        OfferCartForm,
        Modal,
        PartnerList,
    },

    beforeRouteLeave(to, from, next) {
        this.pageLeaving = true;
        this.leavePageResolver = state => {
            this.pageLeaving = false;
            next(state);
        };
    },

    props: {
        action: {
            type: String,
            default() {
                return questionnaireModeNew;
            }
        },
        id: {
            type: [Number, String],
            default() {
                return null;
            }
        }
    },

    data() {
        return {
            startTime: new Date(),
            questionnaireSavedEventName: 'questionnaire-result-n-order-saved',
            currentTime: new Date(),
            questionnaireId: this.id,
            questionnaire: null,
            questions: [],
            selectionValues: {
                filial_id: null,
                filialCoords: null,
                partner_code: null,
                partner_name: null,
                partner_contact: null,
                partner_contact_ref: null,
                partnerDistance: null,
                order_id: null,
                incoming_id: null,
            },
            resultCode: null,
            selectedProducts: [],
            modelLines: [],
            questionnaireProgress: null,
            saveProcess: false,
            recalculate: false,
            calculateResult: {
                products: [],
            },
            get globalFilter() {
                return new Filter();
            },
            contactsList: [],
            // изменились данные для заказа в 1С
            orderDataChanged: false,
            // флаг того, что мы переходим на другой роут
            pageLeaving: false,
            // функция, которая разрешает или запрещает переход по роуту (см. beforeRouteLeave)
            leavePageResolver: Function.prototype,
            // видны ли цены в результатах подбора
            isProductPricesDisplayed: false,
            offerData: {},
            fromOffer: false,
            showPartnerModal: false,
            cartFormExpanded: true,
        };
    },

    computed: {
        branches() {
            return this.$store.state.Catalogs.branches;
        },
        shortList() {
            let short_list = [];

            if (this.questions) {
                short_list = {};
                const questions = [...this.questions];

                for (let i = 0; i < questions.length; i++) {
                    const question = questions[i];

                    if (!question.details.question_category) {
                        question.details.question_category = 'Общие вопросы';
                    }

                    if (typeof short_list[question.details.question_category] === 'undefined') {

                        short_list[question.details.question_category] = {
                            title: question.details.question_category,
                            isSubChecked: true,
                            icon: 'chevron',
                            children: []
                        };
                    }

                    const item = {
                        ...{
                            title: question.details.short_question,
                            icon: 'questionnaire',
                            wrapperClass: (typeof question.class !== 'undefined') ? question.class.join(' ') : '', // error - если ошибка
                            // iconRight: 'exclamation', // Важные
                            url: `#pane-${question.id}`,
                            attributes: {
                                pane_id: `pane-${question.id}`
                            }
                        }
                    };

                    short_list[questions[i].details.question_category].children.push(item);
                }
            }

            return (short_list) ? Object.values(short_list) : [];
        },
        timeFormatted() {
            const output = new Date(0);
            const seconds = Math.abs((this.currentTime.getTime() - this.startTime.getTime()) / 1000);
            output.setSeconds(seconds);

            const color_mx = {
                0: '#000000',
                180: '#cb950a',
                600: '#b10635',
            };

            let color = null;
            Object.keys(color_mx).reverse().forEach(key => {
                if (!color) {
                    if (seconds > key) {
                        color = color_mx[key];
                    }
                }
            });

            return `<span style="color:${color}">${output.toISOString().substr(11, 8)}</span>`;
        },
        questionsForBackend() {
            // преобразуем структуру вопросов в результаты опроса
            return this.questions.reduce((acc, q) => {
                acc[q.id] = {
                    ...q,
                    // eslint-disable-next-line no-nested-ternary
                    answer: Array.isArray(q.answer) ? [...q.answer] : (!!q.answer ? [q.answer] : []),
                    important: q.important ?? 'false',
                };

                return acc;
            }, {});
        },
        /**
         * Отслеживаем изменения в наборе ответов. На это подвязан запуск подбора
         * @returns {*}
         */
        answersHash() {
            return this.questions.reduce((acc, q) => `${acc}${q.answer || ''}`, '');
        },
        /**
         * Отслеживаем изменения в информации о заказе
         * @returns {*}
         */
        selectionValuesHash() {
            return Object.values(this.selectionValues).reduce((acc, value) => `${acc}${value || ''}`, '');
        },

        canRecalculate() {
            return this.selectionValues.filial_id && this.questionnaireId && this.questions.length > 0;
        },

        selectedProductsForCart() {
            return this.selectedProducts?.map(product => ({
                ...product,
                questionnaire: {...this.questionnaire}
            }));
        },
        scenarioRatings() {
            const values = {};
            const scenarioRating = {};
            if (this.questions) {
                Object.values(this.questions).forEach(
                    question => {
                        if (typeof scenarioRating[question.details.question_category] === 'undefined') {
                            scenarioRating[question.details.question_category] = {all: 0, current: 0};
                        }

                        scenarioRating[question.details.question_category].all += question.order;
                        if (
                            typeof question.answer !== 'undefined'
                            && question.answer.length > 0
                        ) {
                            scenarioRating[question.details.question_category].current += question.order;
                        }
                    }
                );

                Object.keys(scenarioRating).forEach(category => {
                    if (scenarioRating[category].all > 0) {
                        values[category] = {
                            title: category,
                            percent: Math.round((scenarioRating[category].current * 100) / scenarioRating[category].all),
                        };
                    }
                });
            }
            return values;
        },
        detailsCalculation() {
            return this.calculateResult?.calculationDetails?.products ?? {};
        },
        calculateTitles() {
            let result = {};

            if (
                this.calculateResult
                && this.calculateResult.products
                && this.calculateResult.calculationDetails
                && this.calculateResult.calculationDetails.questions
                && this.calculateResult.calculationDetails.answers
            ) {
                result = {};
                Object.values(this.calculateResult.calculationDetails.questions).forEach(question => {
                    result[question.question_id] = {
                        id: question.id,
                        externalId: question.question_id,
                        additional: question.additional,
                        title: question.question,
                        answers: {},
                    };
                });
                Object.values(this.calculateResult.calculationDetails.answers).forEach(answer => {
                    if (typeof result[answer.question_id] !== 'undefined') {
                        result[answer.question_id].answers[answer.answer_id] = {
                            id: answer.id,
                            externalId: answer.answer_id,
                            title: answer.answer,
                            properties: answer.properties,
                        };
                    }
                });
            }

            return result;
        },
        managerExternalId() {
            return this.$store.state.User.info.external_id;
        },
    },
    watch: {
        answersHash: {
            async handler(val, oldVal) {
                // обновляем кол-во отвеченных вопросов
                const answered = this.questions.reduce((acc, q) => {
                    if (q?.answer?.length) {
                        return (acc + 1);
                    }
                    return acc;
                }, 0);
                this.questionnaireProgress = `Отвечено ${answered} из ${this.questions.length}`;

                // если хватает данных инициируем подбор товаров
                if (
                    (
                        // значение изменилось или результаты подбора еще не вычислен
                        (val !== oldVal) || (this.calculateResult.products.length === 0)
                    )
                    && this.canRecalculate
                ) {
                    this.recalculate = true;
                }

                // значение изменилось, помечаем, что изменились данные заказа и данные для результата опроса
                if (val !== oldVal && typeof oldVal !== 'undefined') {
                    this.orderDataChanged = true;
                }

            },
            deep: true,
            immediate: true,
        },
        selectionValuesHash: {
            async handler(val, oldVal) {
                if (val !== oldVal && oldVal !== '') {
                    this.orderDataChanged = true;
                }
            },
            deep: true,
            immediate: false,
        },
        'selectionValues.filial_id': {
            async handler(val, oldVal) {
                // при смене филиала инициируем подбор товаров
                if (val && val !== oldVal) {
                    if (this.canRecalculate) {
                        this.recalculate = true;
                    }
                }
            },
            deep: true,
            immediate: false,
        },
        selectedProducts: {
            async handler(val, oldVal) {
                // что-нибудь
                // console.log('***************** selectedProducts', val);
            },
            deep: true,
            immediate: true,
        },
        'selectionValues.partnerDistance': {
            handler() {
                if (this.canRecalculate) {
                    this.recalculate = true;
                }
            }
        }
    },
    beforeCreate() {
        setInterval(() => {
            this.currentTime = new Date();
        }, 1000);
    },
    async beforeMount() {
        await this.parseUrlParams();

        await this.initQuestionnaire();

        await this.initUserFilialInfo();

        eventBus.$on('event-partner-selected', partner => {
            this.handleSelectPartnerEvent(partner);
        });

        eventBus.$on('open-partner-modal', () => this.openPartnerModal());
    },
    beforeUnmount() {
        eventBus.$off('event-partner-selected');
        eventBus.$off('open-partner-modal');
    },
    methods: {
        //region Инициализация опросника
        async parseUrlParams() {
            while (!this.branches?.length) {
                setTimeout(this.parseUrlParams, 50);
                return Promise.resolve();
            }

            const {
                b_id,
                p_id,
                cl_id,
                p_n,
                person,
                or_n,
                vo_n,
                result_code,
                prod_external_id,
                payment_type,
                price,
                offer_hash,
            } = this.$route.query;

            this.resultCode = result_code;

            // TODO 3 подумать, как передать несколько товаров с ценой массивом
            if (prod_external_id && payment_type) {
                this.selectedProducts = [{
                    external_id: prod_external_id,
                    payment_type,
                    price
                }];
            }

            this.selectionValues = {
                ...this.selectionValues,
                // b_id, которое приходит в GET параметрах - это id из 1С, для нас это external_id
                ...(b_id ? {
                    filial_id: (this.branches)
                        ?.find(({external_id}) => +external_id === +b_id)?.id
                } : {}),
                ...(p_id ? {partner_code: p_id} : {}),
                ...(p_n ? {partner_name: p_n} : {}),
                ...(person ? {partner_contact: person} : {}),
                ...(or_n ? {order_id: or_n} : {}),
                ...(vo_n ? {incoming_id: vo_n} : {}),
            };

            if (offer_hash) {
                this.fromOffer = true;
                this.offerData = await getOfferData(
                    {
                        hash: offer_hash,
                    }
                );
                this.selectionValues.order_id = this.offerData?.offer_id;
                this.selectionValues.partner_code = this.offerData?.clients[0]?.id;
                this.selectionValues.partner_name = this.offerData?.clients[0]?.partner_name;
                this.selectionValues.partner_contact = this.offerData?.clients[0]?.users[0]?.first_name;
                this.selectionValues.partner_contact_ref = this.offerData?.clients[0]?.users[0]?.id;
            }

            try {
                if (typeof cl_id !== 'undefined') {
                    this.selectionValues.partner_contact_ref = window.atob(cl_id);
                }
            } catch (e) {
                console.error('cl_id is not base64 value!');
            }

            return Promise.resolve(await this.initFilterValues());
        },
        async initQuestionnaireDataByResult(resultData) {
            this.questionnaireId = resultData.questionnaire_id;

            this.selectionValues.filial_id = resultData.filial_id;
            this.selectionValues.partner_code = resultData.partner_code;
            this.selectionValues.partner_name = resultData.partner_name;
            this.selectionValues.partner_contact = resultData.partner_contact;
            this.selectionValues.partner_contact_ref = resultData.partner_contact_ref;
            this.selectionValues.order_id = resultData.order_id;
            this.selectionValues.incoming_id = resultData.incoming_id;

            this.selectedProducts = resultData.selected_products;
        },

        initQuestionnaireAnswersByResult(resultData) {
            // Объединяем вопросы с результатами опроса
            this.questions = this.questions.map(
                question => {
                    let answer = (resultData?.details[question.id]?.answer || []);

                    // Если в вопросе есть ответы, т.е. единичный/множественный выбор,
                    // то преобразуем id выбранных ответов в Int
                    // Для текстовых вопросов, достаем из массива строку
                    if (question?.answers?.length) {
                        answer = answer.map(a => parseInt(a, 10));
                    } else {
                        answer = [...(resultData?.details[question.id]?.answer || [])].shift();
                    }

                    return {
                        ...question,
                        important: resultData?.details[question.id]?.important,
                        text: [...(resultData?.details[question.id]?.answer || [])].shift(),
                        answer
                    };
                }
            );
        },

        async initQuestionnaire() {
            let resultData = {};

            if (this.action === questionnaireModeEdit) {
                this.resultCode = this.$route.query?.result_code;
                resultData = await getQuestionnaireResult(this.resultCode);
                await this.initQuestionnaireDataByResult(resultData);
            }

            this.questionnaire = await getQuestionnaireDetails(this.questionnaireId);
            this.questions = await getQuestions(this.questionnaireId, true);
            this.modelLines = await getModelLinesForQuestionnaire(this.questionnaireId);

            if (this.action === questionnaireModeEdit) {
                if (typeof resultData.data !== 'undefined' && resultData.data) {
                    this.questionnaire = {...this.questionnaire, ...JSON.parse(resultData.data)};
                }
                this.initQuestionnaireAnswersByResult(resultData);
            }

            // Фиксируем в статистике открытие опросника ЗЧ
            if (this.questionnaire.isPart && this.action !== questionnaireModeEdit && !this.selectedProducts.length) {
                await this.saveQuestionnaireResult([]);
            }
        },

        async initUserFilialInfo() {
            // если id филиала не получено ранее, например, из GET параметров, то ищем его в sessionStorage
            // либо делаем запрос и достаём из юзера
            if (!this.selectionValues.filial_id || !this.selectionValues?.filial_coords) {

                const filial = sessionStorage.getItem('filial');
                const filialCoords = sessionStorage.getItem('filial_coords');

                if (filial && filialCoords) {
                    this.selectionValues.filial_id = +filial;
                    this.selectionValues.filialCoords = JSON.parse(filialCoords);
                } else {
                    await getUserInfo(
                        {key: this.$store.state.User.key},
                        response => {
                            sessionStorage.setItem('filial', response.filial?.id);
                            this.selectionValues.filial_id = response.filial?.id;

                            if (response.filial?.latitude && response.filial?.longitude) {
                                const coords = {
                                    lat: response.filial?.latitude.trim(),
                                    lon: response.filial?.longitude.trim(),
                                };
                                this.selectionValues.filialCoords = coords;
                                sessionStorage.setItem('filial_coords', JSON.stringify(coords));
                            }
                        },
                        e => {
                            console.error(e);
                            sessionStorage.removeItem('user_key');
                            this.$store.dispatch('updateUserKey', null);
                        }
                    );
                }
            }
        },

        async initFilterValues() {
            const filter = this.globalFilter.getGroup('partner');
            if (this.selectionValues.partner_code && this.selectionValues.partner_contact_ref) {
                await getPartnerApi(
                    {external_id: this.selectionValues.partner_code},
                    partner => {
                        filterHelper
                            .selectPartnerGlobal(partner, null, this.selectionValues.partner_contact_ref);
                        this.calculatePartnerDistance(partner);
                    }
                );

            } else if (Object.values(filter).length) {
                this.selectPartner(filter);
                if (filter.selectedContact && filter.contacts.length) {
                    const selectedContact = filter.contacts.find(contact => contact.id === filter.selectedContact);
                    if (selectedContact) {
                        this.selectContact(selectedContact);
                    }
                }
            }
        },
        //endregion

        /**
         * Сохранение результата Опроса. При наличии объекта updateData будут обновлены только
         * поля содержащиеся в нём
         *
         * @param products Array
         * @param resultCode [Array, String]
         * @param updateData Object
         * @returns {Promise<null>}
         */
        async saveQuestionnaireResult(products, resultCode = null, updateData = null) {
            this.saveProcess = true;

            const {name: userName, surname} = this.$store.state.User.info;

            const data = updateData || {
                ...this.selectionValues,
                question: this.questionsForBackend,
                result_code: resultCode,
                product_category_name: this.questionnaire.name,
                manager_name: `${surname} ${userName}`,
                questionnaire_id: this.questionnaireId,
                scenarioRatings: this.scenarioRatings,
                selected_products: products,
                questionnaireData: {...this.questionnaireData, comment: this.questionnaire.comment},
                answerPoints: this.getAnswerRatings(products),
            };

            if (updateData) {
                data.result_code = resultCode;
            }

            const newResultCode = await setQuestionnaireResult({...data}).catch(() => {
                this.saveProcess = false;
            });

            // сохранение может происходить для нескольких результатов, в этом случае не обновляем this.resultCode
            this.resultCode = !Array.isArray(newResultCode) ? newResultCode : this.resultCode;
            // this.questionnaireResultDataChanged = false;
            this.saveProcess = false;

            return this.resultCode;
        },

        getAnswerRatings(selectedProducts) {

            const multiofferCart = this.globalFilter.getGroup('multioffer_cart', []);
            const currentProductDetails = {};
            [...selectedProducts, ...multiofferCart].forEach(product => {
                currentProductDetails[product.id] = this.detailsCalculation[product.id];
            });

            const formatProperties = properties => {
                const result = {};
                Object.values(properties).forEach(item => {
                    result[item.featureId] = {
                        points: item.points,
                        value: item.value,
                    };
                });
                return result;
            };

            const result = {};
            if (this.calculateResult.products.length > 0) {
                Object.values(this.calculateTitles).forEach(question => {
                    Object.values(question.answers).forEach(answer => {
                        const productRates = {};
                        Object.keys(currentProductDetails).forEach(productId => {
                            if (
                                typeof currentProductDetails[productId] !== 'undefined'
                                && typeof currentProductDetails[productId].questions !== 'undefined'
                                && typeof currentProductDetails[productId].questions[question.externalId] !== 'undefined'
                                && typeof currentProductDetails[productId].questions[question.externalId].answers !== 'undefined'
                                && typeof currentProductDetails[productId].questions[question.externalId].answers[answer.externalId] !== 'undefined'
                            ) {
                                const {points} = currentProductDetails[productId].questions[question.externalId].answers[answer.externalId];
                                productRates[productId] = {
                                    points,
                                    percent: Math.round((parseFloat(points) / 2) * 100),
                                    details: formatProperties(currentProductDetails[productId].questions[question.externalId].answers[answer.externalId].properties),
                                };
                            }
                        });
                        result[answer.id] = productRates;
                    });
                });
            }


            return result;
        },

        /**
         * Переход к созданию КП
         * @param products
         * @param offertaHash
         * @param openOfferInBrowser
         */
        async sendKp(products, offertaHash, openOfferInBrowser = true) {

            const product = [...products].pop();

            const price = {
                type: '',
                additional_params: []
            };

            const priceData = product.price;
            price.type = product?.payment_type;

            const params = {
                managerId: this.managerExternalId,
                kpHash: this.offerData.kpHash,
            };

            if (
                priceData
                && typeof priceData.details !== 'undefined'
                && typeof priceData.details.prepaid !== 'undefined'
                && typeof priceData.details.term !== 'undefined'
            ) {
                price.additional_params.push(`calculator[prepay_percent]=${priceData.details.prepaid.percent}`);
                price.additional_params.push(`calculator[term]=${priceData.details.term}`);
                params.calculator = {
                    prepay_percent: priceData.details.prepaid.percent,
                    term: priceData.details.term,
                };

            }

            if (products.length > 0 && !products.find(p => !p.questionnaire_result) && this.selectionValues.filial_id) {
                const productIds = [];
                const questionnaireResultIds = {};
                products.forEach(p => {
                    productIds.push(p.external_id);
                    questionnaireResultIds[p.external_id] = p?.questionnaire_result;
                });

                params.external_id = productIds;
                params.questionnaire_results_id = questionnaireResultIds;
                params.filial_id = this.selectionValues.filial_id;
                params.price_type = price.type;

                if (offertaHash) {
                    params.offertaHash = offertaHash;
                }

                const offerUrl = await createKp(params);
                const link = helpers.urlSetParams(
                    `${process.env.VUE_APP_HOST_URL}${offerUrl}`,
                    [['manage', 1]]
                );

                if (openOfferInBrowser) {
                    return Promise.resolve(window.open(link, '_blank'));
                }
            }

            return Promise.resolve();
        },

        // region Работа с контактами
        selectPartner(partner) {
            this.selectionValues.partner_code = partner.external_id;
            this.selectionValues.partner_name = partner.name;

            this.selectionValues.partner_contact = null;
            if (partner.selectedContact && partner.contacts && partner.contacts.length) {
                const currentContact = partner.contacts.find(p => p.id === partner.selectedContact);
                if (currentContact) {
                    this.selectionValues.partner_contact = currentContact.name;
                }
            }

            this.calculatePartnerDistance(partner);
            this.getContact(partner.id);
        },

        selectContact(contact) {
            this.selectionValues.partner_contact = contact.first_name
                ? `${contact.first_name} ${contact.middle_name}`
                : contact.name;
            this.selectionValues.partner_contact_ref = contact.Ref_Key || null;
        },

        async getContact(id) {
            this.contactsList = await getContactApi(id);
        },
        // endregion

        //region Мультиоферта
        async leavePageHandler(products) {
            // await this.saveQuestionnaireResult(products, this.resultCode);
        },

        async addToOrderHandler(products) {
            const result = products.length && await this.saveQuestionnaireResult(
                [...products]
            );

            if (result) {
                this.selectedProducts = [];
                this.orderDataChanged = true;
            }

            return result;
        },

        saveOrderHandler(products, resultCode, callback = null) {
            this.save1COrder(
                [...products],
                resultCode,
                () => {
                    if (callback) {
                        callback();
                    }

                    eventBus.$emit(this.questionnaireSavedEventName);
                }
            );
        },

        removeFromOrderHandler(products, resultCode) {
            this.orderDataChanged = true;
            return this.saveQuestionnaireResult(
                [...products],
                resultCode
            );
        },

        async createOfferHandler(products, resultCode, openOfferInBrowser = true) {
            let result = null;

            result = await createOffer(
                {
                    filial_id: this.selectionValues.filial_id,
                    products,
                    questionnaire_result_id: resultCode,
                }
            );

            await this.sendKp(products, result, openOfferInBrowser);

            return Promise.resolve(result);
        },
        // endregion

        // region Заказ 1C
        canSave1COrder() {
            const partnerCodeIsValid = (this.selectionValues.partner_code && this.selectionValues.partner_code?.length === 10);

            if (!partnerCodeIsValid) {
                alert('Не выбран контрагент, заказ не будет сохранен в 1с');
                return false;
            }

            return true;
        },

        getSelectedProductsForBack(products) {
            return products.map(product => ({
                product_code: product?.external_id,
                payment_type: product?.payment_type,
                price: product?.prices[product?.payment_type]?.local_price,
                originalPrice: product?.originalPrice,
                commissioning: product?.default_commissioning ? 1 : 0,
                count: product?.count ?? 1,
            }));
        },

        async save1COrder(products, resultCode, callback = null) {
            if (this.canSave1COrder()) {
                const productsData = this.getSelectedProductsForBack(products);
                const direction = (this.questionnaire?.category_type === categoryTypeTechnics) ? c1SaveDirectionTechnics : c1SaveDirectionSpareParts;

                let callbackProcessed = false;
                const timeoutCallback = setTimeout(() => {
                    alert('Сервер 1С недоступен, заказ не сохранен!');
                    if (callback) {
                        callbackProcessed = true;
                        callback();
                    }
                }, ORDER_CREATE_DEFAULT_TIMEOUT);

                await save1COrderApi(
                    {
                        direction,
                        ...(this.selectionValues.order_id ? {order_id: this.selectionValues.order_id} : {}),
                        ...(this.selectionValues.incoming_id ? {incoming_id: this.selectionValues.incoming_id} : {}),
                        client_code: this.selectionValues.partner_code,
                        clientDistance: this.selectionValues.partnerDistance,
                        branch_code: this.selectionValues.filial_id,
                        ...(productsData.length === 0 ? {product_group_code: [...this.questionnaire?.product_groups].shift()} : {}),
                        ...(productsData.length > 0 ? {products: [...productsData]} : {}),
                    },
                    async orderId => {
                        if (callbackProcessed) {
                            return;
                        }

                        this.selectionValues.order_id = orderId;
                        await this.saveQuestionnaireResult(
                            products,
                            resultCode
                        );

                        this.orderDataChanged = false;

                        clearTimeout(timeoutCallback);
                        if (callback) {
                            callback();
                        }
                    },
                    err => {
                        alert(err);

                        if (callbackProcessed) {
                            return;
                        }

                        clearTimeout(timeoutCallback);
                        if (callback) {
                          callback();
                        }
                    }
                );
            } else {
                this.orderDataChanged = false;
                if (callback) {
                    callback();
                }
            }
        },
        // endregion

        async save1cOrderFromOffer(products, callback = null) {
            let result = false;
            if (this.canSave1COrder()) {
                const direction = (this.questionnaire?.category_type === categoryTypeTechnics) ? 1 : 2;
                await save1COrderApi(
                    {
                        direction,
                        ...(this.selectionValues.order_id ? {order_id: this.selectionValues.order_id} : {}),
                        ...(this.selectionValues.incoming_id ? {incoming_id: this.selectionValues.incoming_id} : {}),
                        client_code: this.selectionValues.partner_code,
                        branch_code: this.selectionValues.filial_id,
                        ...(products.length === 0 ? {product_group_code: [...this.questionnaire?.product_groups].shift()} : {}),
                        ...(products.length > 0 ? {products: [...products]} : {}),
                    },
                    success => {
                        if (callback) {
                            callback();
                        }
                        result = true;
                    },
                    err => {
                        window.alert(err);
                        result = false;
                    }
                );
            } else {
                result = true;
            }

            return result;
        },

        addSelectedProduct(product, priceType, price = null) {
            this.selectedProducts = [
                ...this.selectedProducts.filter(
                    ({external_id}) => !(external_id === product.external_id)
                ),
                {
                    name: product.name,
                    external_id: product.external_id,
                    id: product.id,
                    payment_type: priceType,
                    price: price || product?.prices?.[priceType],
                    originalPrice: product?.prices?.price?.details?.priceWithoutCommissioning?.total
                        || product?.prices?.price?.price,
                    commissioning: product.prices?.commissioning?.local_price,
                    count: 1,
                }
            ];
        },
        removeSelectedProduct(productId, priceType) {
            this.selectedProducts = this.selectedProducts.filter(
                ({external_id, payment_type}) => !(external_id === productId && payment_type === priceType)
            );
        },
        openPartnerModal() {
            this.showPartnerModal = true;
            this.cartFormExpanded = false;
        },
        closePartnerModal() {
            this.showPartnerModal = false;
            this.cartFormExpanded = true;
        },
        handleSelectPartnerEvent(partner) {
            if (Object.keys(partner).length === 0) {
                this.selectionValues.partner_name = null;
                this.selectionValues.partner_contact = null;
                this.selectionValues.partner_code = null;
                this.selectionValues.partner_contact_ref = null;
                this.selectionValues.partnerDistance = null;
                return;
            }

            this.selectionValues.partner_code = partner?.external_id;
            this.selectionValues.partner_name = partner?.name;

            if (partner?.areaData && partner?.areaData?.id) {
                this.calculatePartnerDistance(partner);
            }

            if (partner.selectedContact && Array.isArray(partner.contacts)) {
                const partnerContact = partner.contacts.find(contact => contact.id === partner.selectedContact);
                if (partnerContact) {
                    this.selectionValues.partner_contact = partnerContact.first_name;
                    this.selectionValues.partner_contact_ref = partnerContact.Ref_Key;
                }
            }
        },
        calculatePartnerDistance(partner) {
            const filialCoords = this.selectionValues.filialCoords ?? JSON.parse(sessionStorage.getItem('filial_coords'));

            getAreaCoords(partner.areaData.id, responseData => {
                helpers.calculateDistanceYMaps({
                    fromLat: filialCoords?.lat,
                    fromLon: filialCoords?.lon,
                    toLat: responseData.lat,
                    toLon: responseData.lon,
                }).then(ymapsDistance => {
                    this.selectionValues.partnerDistance = ymapsDistance;
                });
            }, (responseData, response) => {
                console.error(response);
            });
        },
    }
};
</script>
