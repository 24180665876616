<div class="cart-form">
    <div v-if="isLoading">
        <div>{{ loadingMsg }}</div>
        <Loader state="fixed" :minHeight="199" />
    </div>
    <div v-if="productsCount > 0" :class="['cart-form__container', formExpanded === true ? 'open' : '']">
        <div class="cart-form__row">
            <div
                v-for="groupedProducts in productsFormatted"
                class="cart-form__block"
            >
                <div class="cart-form__title">{{ groupedProducts.questionnaire.name }}</div>
                <div class="cart-form__items">
                    <div
                        v-for="product in groupedProducts.products"
                        class="cart-form__item"
                    >
                        <span>{{ product.name }}</span>
                        <counter :value="product.count" @on-change-value="setProductCount(product.external_id, $event)" />
                        <a
                            @click.prevent="removeProduct(groupedProducts.questionnaire.id, product.external_id)"
                            class="cart-form__item-close"
                        >
                            <span class="icon close"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="cart-form__actions">
            <a
                @click.prevent="newOrder"
                class="cart-form__action"
            >
                <span class="icon help-circle"></span>
                <span>Новый заказ</span>
            </a>
            <a
                @click.prevent="saveOrder"
                class="cart-form__action"
            >
                <span class="icon save"></span>
                <span>Сохранить заказ</span>
            </a>
        </div>
    </div>

    <div :class="['button-container', productsCount > 0 ? 'count' : '']">
        <a
            v-if="productsCount > 0 && questionnaire && questionnaire.isTech"
            class="button button-send"
            @click.prevent="sendKp"
        >
            Отправить КП (<span>{{ productsCount }}</span>)
        </a>
        <a
            class="button button-add"
            href="#"
            @click.prevent="addSelectedProducts()"
        >
            + Добавить в заказ
        </a>
        <a
            v-if="productsCount > 0"
            :class="['button', 'button-open', formExpanded === true ? 'open' : '']"
            v-on:click="toggleForm"
        >
            <span class="icon arrow"></span>
<!--            <span class="button-open__counter">{{ productsCount }}</span>-->
        </a>
    </div>
</div>
